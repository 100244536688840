// src/components/Board.scss

.board {
    display: grid;
    grid-template-columns: repeat(11, 1fr); // 11 columns for the full board layout
    grid-template-rows: repeat(11, 1fr);    // 11 rows for the full board layout
    width: 90vmin; // Board is square and responsive, using viewport size
    height: 90vmin;
    border: 5px solid #000;
    margin: 20px auto;
    position: relative;
  
    // Tile styling to be responsive
    .tile {
      border: 2px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      font-size: calc(0.5vw + 0.5em);
      padding: calc(0.5vw + 5px);
      background-color: #f0f0f0;
      word-wrap: break-word;
  
      &.corner {
        background-color: #2a9d8f;
        font-weight: bold;
      }
  
      &.property {
        background-color: #f4a261;
      }
  
      &.special {
        background-color: #e9c46a;
      }
  
      &.railroad {
        background-color: #264653;
        color: #fff;
      }
  
      &.utility {
        background-color: #e76f51;
      }
    }
  
    // Define grid positions for each tile to avoid overflowing the board
  
    // Bottom row (tiles 1 to 11)
    .tile:nth-child(1) { grid-area: 11 / 11 / 12 / 12; } // Go
    .tile:nth-child(2) { grid-area: 11 / 10 / 12 / 11; }
    .tile:nth-child(3) { grid-area: 11 / 9 / 12 / 10; }
    .tile:nth-child(4) { grid-area: 11 / 8 / 12 / 9; }
    .tile:nth-child(5) { grid-area: 11 / 7 / 12 / 8; }
    .tile:nth-child(6) { grid-area: 11 / 6 / 12 / 7; }
    .tile:nth-child(7) { grid-area: 11 / 5 / 12 / 6; }
    .tile:nth-child(8) { grid-area: 11 / 4 / 12 / 5; }
    .tile:nth-child(9) { grid-area: 11 / 3 / 12 / 4; }
    .tile:nth-child(10) { grid-area: 11 / 2 / 12 / 3; }
    .tile:nth-child(11) { grid-area: 11 / 1 / 12 / 2; } // Jail/Just Visiting
  
    // Left column (tiles 12 to 21)
    .tile:nth-child(12) { grid-area: 10 / 1 / 11 / 2; }
    .tile:nth-child(13) { grid-area: 9 / 1 / 10 / 2; }
    .tile:nth-child(14) { grid-area: 8 / 1 / 9 / 2; }
    .tile:nth-child(15) { grid-area: 7 / 1 / 8 / 2; }
    .tile:nth-child(16) { grid-area: 6 / 1 / 7 / 2; }
    .tile:nth-child(17) { grid-area: 5 / 1 / 6 / 2; }
    .tile:nth-child(18) { grid-area: 4 / 1 / 5 / 2; }
    .tile:nth-child(19) { grid-area: 3 / 1 / 4 / 2; }
    .tile:nth-child(20) { grid-area: 2 / 1 / 3 / 2; }
    .tile:nth-child(21) { grid-area: 1 / 1 / 2 / 2; } // Free Parking
  
    // Top row (tiles 22 to 31)
    .tile:nth-child(22) { grid-area: 1 / 2 / 2 / 3; }
    .tile:nth-child(23) { grid-area: 1 / 3 / 2 / 4; }
    .tile:nth-child(24) { grid-area: 1 / 4 / 2 / 5; }
    .tile:nth-child(25) { grid-area: 1 / 5 / 2 / 6; }
    .tile:nth-child(26) { grid-area: 1 / 6 / 2 / 7; }
    .tile:nth-child(27) { grid-area: 1 / 7 / 2 / 8; }
    .tile:nth-child(28) { grid-area: 1 / 8 / 2 / 9; }
    .tile:nth-child(29) { grid-area: 1 / 9 / 2 / 10; }
    .tile:nth-child(30) { grid-area: 1 / 10 / 2 / 11; }
    .tile:nth-child(31) { grid-area: 1 / 11 / 2 / 12; } // Go to Jail
  
    // Right column (tiles 32 to 40)
    .tile:nth-child(32) { grid-area: 2 / 11 / 3 / 12; }
    .tile:nth-child(33) { grid-area: 3 / 11 / 4 / 12; }
    .tile:nth-child(34) { grid-area: 4 / 11 / 5 / 12; }
    .tile:nth-child(35) { grid-area: 5 / 11 / 6 / 12; }
    .tile:nth-child(36) { grid-area: 6 / 11 / 7 / 12; }
    .tile:nth-child(37) { grid-area: 7 / 11 / 8 / 12; }
    .tile:nth-child(38) { grid-area: 8 / 11 / 9 / 12; }
    .tile:nth-child(39) { grid-area: 9 / 11 / 10 / 12; }
    .tile:nth-child(40) { grid-area: 10 / 11 / 11 / 12; } // Boardwalk
  }
  
  @media (max-width: 600px) {
    .board {
      width: 95vmin; // Make the board slightly larger on small screens for better visibility
      height: 95vmin;
    }
  
    .tile {
      font-size: calc(0.4vw + 0.8em); // Increase font size for readability on smaller screens
      padding: calc(0.4vw + 4px);
    }
  }
  