.admin-container {
  overflow: hidden !important;
  height: 100vh;
  width: 100vw;
  padding: 0 32px 0 32px;
  background: url(../public/homepage-background.webp);
  // background: url(../public/homepage-background.webp),
  //   url(../public/Group-13980.webp);
  background-size: cover;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
  .header {
    position: fixed;
    padding: 24px 0 24px 0;
    z-index: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: auto;
    width: 95%;
  }

  .export-btn-container {
    top: 55px;
    display: block;
    position: relative;
    text-align: end;
    .export-btn {
      border: 1px solid #fb8b23;
      border-radius: 4px;
      background: #fb8b23;
      color: #fff;
      height: 40px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      padding: 8px;
      width: auto;
    }
  }

  .table-container {
    position: relative;
    top: 60px;
    height: calc(100% - 106px);
    overflow-y: auto;
  }
}
