.game-body {
  height: 100vh;
  width: 100vw;
  display: flex;
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: url(../public/homepage-background.webp),
    url(../public/Group-13980.webp);
  background-size: cover;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
  .left-side {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    padding-left: 20px;
    .header {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 24px 12px 0 12px;
      height: 74px;
      .gameTitle {
        font-size: 24px;
        padding-left: 10px;
        height: 25px;
        font-weight: 900;
        color: #f68b28;
      }
    }
    .how-to-container {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0;
      .guide {
        height: 50%;
        .userSection {
          h3 {
            margin-bottom: 10px;
            margin-top: 10px;
          }
          em {
            color: #f68b28;
          }
          .btn-container {
            margin-top: 20px;
            .how-guide-btn {
              display: none;
            }
            .primary-btn {
              border: 1px solid #f68b28;
              border-radius: 40px;
              background: #f68b28;
              color: #fff;
              height: 40px;
              width: 200px;
              font-family: "Montserrat", sans-serif;
              cursor: pointer;
              margin-right: 20px;
              outline: none;
              transition: box-shadow 0.3s ease, transform 0.3s ease;
              &:hover {
                background: #f68b28;
                color: #fff;
                -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                transform: translate3d(0, -1px, 0);
                transition: All 500ms ease;
                -webkit-transition: All 500ms ease;
              }
            }

            .primary-btn:focus{
              border: 2px solid #fff;
              outline: 2px solid #f68b28;
              font-weight: 600;
            }

            .secondary-btn {
              border: 1px solid #26619c;
              border-radius: 40px;
              background: #26619c;
              color: #fff;
              height: 40px;
              width: 200px;
              font-family: "Montserrat", sans-serif;
              cursor: pointer;
              margin-right: 20px;
              &:hover {
                background: #26619c;
                color: #fff;
                -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
                transform: translate3d(0, -1px, 0);
                transition: All 500ms ease;
                -webkit-transition: All 500ms ease;
              }
            }
          }
        }
        .howToPlayGuideContainer {
          margin-top: 25px;
          backdrop-filter: blur(15px);
          background: hsla(0, 0%, 100%, 0.639);
          border-radius: 15px;
          box-shadow: 0 4px 10px #0003;
          padding: 14px;
        }
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: row;
    height: calc(100% - 20px);
    position: relative;
    width: 50%;
    padding: 20px 0 0 0;
    .game-container {
      position: relative;
      overflow-y: hidden;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      .game-board {
        background-image: url(../public/FINOPOLY.svg);
        background-repeat: no-repeat, no-repeat;
        background-position: center;
        background-size: 100% 100%;
        height: 550px;
        width: 550px;
        padding: 1px 2px 1px 2px;
        position: relative;
        .top-row {
          display: flex;
          justify-content: space-evenly;
          height: 80px;
          width: 87px;
          position: relative;
          top: 0;
          height: 80px;
          width: 100%;

          .game-block {
            border: transparent;
            height: 80px;
            width: 47px;
          }

          .corner-block {
            height: 80px;
            width: 87px;
          }

          .corner-block:last-child {
            border: transparent;
            height: 80px;
            width: 87px;
          }
        }
        .middle-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 0px;
          height: 387px;
          .game-block {
            border: transparent;
            width: 81px;
            height: 43px;
          }

          .center-col {
            justify-content: center;
            vertical-align: center;
          }
        }
        .bottom-row {
          display: flex;
          justify-content: space-evenly;
          position: relative;
          top: 0;
          height: 80px;
          .game-block {
            border: transparent;
            height: 80px;
            width: 47px;
          }

          .corner-block:first-child {
            height: 80px;
            width: 87px;
          }

          .corner-block:last-child {
            height: 80px;
            width: 87px;
            border: transparent;
          }
        }

        .visited-question-border {
          border: 3px solid #51087e !important;
        }

        .blinking-border {
          animation: blink 1s infinite !important;
        }

        @keyframes blink {
          0% {
            border: 3px solid #51087e;
          }
          50% {
            border: none;
          }
          100% {
            border: 3px solid #51087e
          }
        }

        .avatar {
          display: none !important;
          position: absolute;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          // bottom: 10px;
          // right: 10px;
          right: 30px;
          bottom: 25px;
          background-color: #1e1e1e;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

          .avatarImg {
            height: 15px;
            width: 15px;
            background: url(../public/game-token.svg);
            background-size: cover;
            background-position: top center, bottom center;
            background-repeat: no-repeat;
          }
        }

        .dice-area {
          position: absolute;
          height: 52px;
          width: 104px;
          top: 30%;
          left: 30%;
          pointer-events: none;
        }

        .timer-wrapper {
          display: flex;
          justify-content: center;
        }

        .timer {
          font-family: "Montserrat";
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .question-form {
          display: flex !important;
          max-width: 200px !important;
        }
      }
    }
  }

  .email-input-box {
    padding: 12px;
    margin: 10px 0;
    border-radius: 8px;
    border: 2px solid #fb8b23;
    outline: none;
    font-size: 1rem;
    color: #1e1e1e;
  }
}
.voucherContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 50px;
  margin-top: 28px;
  span {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  img {
    width: 100px;
    cursor: pointer;
    &:focus {
      outline: #f68b28;
      border: 1px solid #f68b28;
    }
  }
  .selected {
    outline: #f68b28;
    border: 2px solid #f68b28;
  }
}

@media only screen and (min-width: 350px) and (max-width: 379px) {
  .game-body {
    overflow-y: auto;
    flex-direction: column;
    .left-side {
      width: 100%;
      display: flex;
      padding-right: 20px;
      flex-direction: column;
      .header {
        justify-content: center;
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: center;
              column-gap: 20px;
              .how-guide-btn {
                display: block;
                background: none;
                border: 2px solid #26619c;
                border-radius: 50%;
                height: 40px;
                width: 40px;
              }
              .primary-btn {
                border: 1px solid #f68b28;
                margin: 0;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
                margin: 0;
              }
            }
          }
          .howToPlayGuideContainer {
            display: none;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;
      width: 100%;
      padding: 20px 0 20px 0;

      .game-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        // padding: 0 20px 0 20px;
        align-items: center;
        .game-board {
          height: 300px;
          width: 300px;
          position: relative;
          .top-row {
            top: 0px;
            position: relative;
            height: 43px;
            .game-block {
              height: 43px;
              width: 42px;
            }

            .corner-block {
              width: 80px;
              height: 42px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 42px;
            }
          }
          .middle-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            height: 211px;
            position: relative;
            top: 0px;
            .game-block {
              width: 43px;
              height: 24px;
            }

            .center-col {
            }
          }
          .bottom-row {
            top: 0px;
            position: relative;
            height: 43px;
            .game-block {
              height: 43px;
              width: 42px;
            }

            .corner-block {
              height: 80px;
              width: 87px;
            }

            .corner-block:last-child {
              height: 80px;
              width: 87px;
            }
          }

          .avatar {
            display: none !important;
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            right: 10px;
            bottom: 10px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 380px) and (max-width: 479px) {
  .game-body {
    overflow-y: auto;
    flex-direction: column;
    .left-side {
      width: 100%;
      display: flex;
      padding-right: 20px;
      flex-direction: column;
      .header {
        justify-content: center;
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: center;
              column-gap: 20px;
              .how-guide-btn {
                display: block;
                background: none;
                border: 2px solid #26619c;
                border-radius: 50%;
                height: 40px;
                width: 40px;
              }
              .primary-btn {
                border: 1px solid #f68b28;
                margin: 0;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
                margin: 0;
              }
            }
          }
          .howToPlayGuideContainer {
            display: none;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;
      width: 100%;
      padding: 20px 0 20px 0;

      .game-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        // padding: 0 20px 0 20px;
        align-items: center;
        .game-board {
          height: 350px;
          width: 350px;
          position: relative;
          .top-row {
            top: 0px;
            position: relative;
            height: 50px;
            .game-block {
              height: 50px;
              width: 42px;
            }

            .corner-block {
              width: 80px;
              height: 50px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 50px;
            }
          }
          .middle-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            height: 246px;
            position: relative;
            top: 0px;
            .game-block {
              width: 50px;
              height: 27px;
            }

            .center-col {
            }
          }
          .bottom-row {
            top: 0px;
            position: relative;
            height: 50px;
            .game-block {
              height: 50px;
              width: 42px;
            }

            .corner-block {
              width: 80px;
              height: 50px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 50px;
            }
          }

          .avatar {
            display: none !important; 
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            right: 10px;
            bottom: 10px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .game-body {
    overflow-y: auto;
    flex-direction: column;
    .left-side {
      width: 100%;
      display: flex;
      padding-right: 20px;
      flex-direction: column;
      .header {
        justify-content: center;
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: center;
              column-gap: 20px;
              .how-guide-btn {
                display: block;
                background: none;
                border: 2px solid #26619c;
                border-radius: 50%;
                height: 40px;
                width: 40px;
              }
              .primary-btn {
                border: 1px solid #f68b28;
                margin: 0;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
                margin: 0;
              }
            }
          }
          .howToPlayGuideContainer {
            display: none;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;
      width: 100%;
      padding: 20px 0 20px 0;

      .game-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        padding: 0 20px 0 20px;
        align-items: center;
        .game-board {
          min-height: 400px;
          height: 400px;
          width: 430px;
          .top-row {
            display: flex;
            position: relative;
            top: 0;
            height: 60px;
            .game-block {
              height: 60px;
              width: 34px;
            }

            .corner-block {
              width: 80px;
              height: 60px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 60px;
            }
          }
          .middle-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-left: 4%;
            padding-right: 4%;
            height: 280px;
            position: relative;
            top: 0px;
            .game-block {
              width: 58px;
              height: 31px;
            }

            .center-col {
            }
          }
          .bottom-row {
            display: flex;
            justify-content: center;
            height: 60px;
            top: 0;
            position: relative;
            .game-block {
              height: 60px;
              width: 34px;
            }

            .corner-block:first-child {
              width: 80px;
              height: 60px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 60px;
            }
          }

          .avatar {
            display: none !important;
            position: absolute;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            // bottom: 10px;
            // right: 10px;
            right: 80px;
            bottom: 25px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .game-body {
    overflow-y: auto;
    flex-direction: row;
    .left-side {
      .header {
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            // text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: flex-start;
              .how-guide-btn {
                display: none;
              }
              .primary-btn {
                border: 1px solid #f68b28;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
              }
            }
          }
          .howToPlayGuideContainer {
          }
        }
      }
    }

    .right-side {
      .game-container {
        padding: 0px;
        align-items: center;
        justify-content: center;
        .game-board {
          min-height: 340px;
          height: 340px;
          width: 340px;
          .top-row {
            display: flex;
            top: 0;
            position: relative;
            height: 49px;
            .game-block {
              height: 49px;
              width: 43px;
            }

            .corner-block {
              width: 80px;
              height: 49px;
            }

            .corner-block:last-child {
              width: 80px;
              height: 49px;
            }
          }
          .middle-row {
            height: 239px;
            position: relative;
            top: 0px;
            padding-left: 0;
            padding-right: 0;
            .game-block {
              width: 50px;
              height: 27px;
            }

            .center-col {
            }
          }
          .bottom-row {
            display: flex;
            justify-content: center;
            height: 49px;
            top: 0px;
            position: relative;
            .game-block {
              height: 49px;
              width: 43px;
            }

            .corner-block:first-child {
              height: 49px;
              width: 80px;
            }

            .corner-block:last-child {
              height: 49px;
              width: 80px;
            }
          }

          .avatar {
            display: none !important;
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            right: 25px;
            bottom: 20px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .game-body {
    overflow-y: auto;
    flex-direction: row;
    .left-side {
      .header {
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            // text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: flex-start;
              .how-guide-btn {
                display: none;
              }
              .primary-btn {
                border: 1px solid #f68b28;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
              }
            }
          }
          .howToPlayGuideContainer {
          }
        }
      }
    }

    .right-side {
      .game-container {
        padding: 0 20px 0 20px;
        .game-board {
          min-height: 400px;
          height: 440px;
          width: 438px;
          .top-row {
            display: flex;
            position: relative;
            top: 0;
            height: 63px;
            .game-block {
              height: 64px;
              width: 39px;
            }

            .corner-block {
              height: 64px;
              width: 74px;
            }

            .corner-block:last-child {
              height: 64px;
              width: 74px;
            }
            
          }
          .middle-row {
            padding-left: 0;
            padding-right: 0;
            position: relative;
            top: 0px;
            height: 307px;
            .game-block {
              width: 65px;
              height: 34px;
            }

            .center-col {
            }
          }
          .bottom-row {
            position: relative;
            top: 0px;
            height: 63px;
            .game-block {
              height: 63px;
              width: 43px;
            }

            .corner-block:first-child {
              height: 63px;
              width: 80px;
            }

            .corner-block:last-child {
              height: 63px;
              width: 80px;
            }
          }

          .avatar {
            display: none !important;
            position: absolute;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            // bottom: 10px;
            // right: 10px;
            right: 40px;
            bottom: 25px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px)
{
  .game-body {
    overflow-y: auto;
    flex-direction: row;
    .left-side {
      .header {
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            // text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: flex-start;
              .how-guide-btn {
                display: none;
              }
              .primary-btn {
                border: 1px solid #f68b28;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
              }
            }
          }
          .howToPlayGuideContainer {
          }
        }
      }
    }

    .right-side {
      .game-container {
        padding: 0 20px 0 20px;
        .game-board {
          background-image: url(../public/FINOPOLY.svg);
          background-repeat: no-repeat, no-repeat;
          background-position: center;
          background-size: 100% 100%;
          height: 550px;
          width: 550px;
          position: relative;
          .top-row {
            display: flex;
            justify-content: space-evenly;
            height: 80px;
            width: 87px;
            position: relative;
            top: 0;
            height: 80px;
            width: 100%;
            .game-block {
              border: transparent;
              height: 80px;
              width: 47px;
            }
  
            .corner-block {
              height: 80px;
              width: 87px;
            }
  
            .corner-block:last-child {
              border: transparent;
              height: 80px;
              width: 87px;
            }
          }
          .middle-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 0px;
            height: 387px;
            .game-block {
              border: transparent;
              width: 81px;
              height: 43px;
            }
  
            .center-col {
              justify-content: center;
              vertical-align: center;
            }
          }
          .bottom-row {
            display: flex;
            justify-content: space-evenly;
            position: relative;
            top: 0;
            height: 80px;
            .game-block {
              border: transparent;
              height: 80px;
              width: 47px;
            }
  
            .corner-block:first-child {
              height: 80px;
              width: 87px;
            }
  
            .corner-block:last-child {
              height: 80px;
              width: 87px;
              border: transparent;
            }
          }
  
          .visited-question-border {
            border: 3px solid #51087e !important;
          }
  
          .blinking-border {
            animation: blink 1s infinite !important;
          }
  
          @keyframes blink {
            0% {
              border: 3px solid #51087e;
            }
            50% {
              border: none;
            }
            100% {
              border: 3px solid #51087e
            }
          }
  
          .avatar {
            display: none !important;
            position: absolute;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            // bottom: 10px;
            // right: 10px;
            right: 30px;
            bottom: 25px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);
  
            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }
  
          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }
  
          .timer-wrapper {
            display: flex;
            justify-content: center;
          }
  
          .timer {
            font-family: "Montserrat";
            display: flex;
            flex-direction: column;
            align-items: center;
          }
  
          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1619px) {
  .game-body {
    overflow-y: auto;
    flex-direction: row;
    .left-side {
      .header {
        .gameTitle {
        }
      }
      .how-to-container {
        display: flex;
        flex-direction: column;
        .guide {
          .userSection {
            // text-align: center;
            h3 {
            }
            em {
            }
            .btn-container {
              display: flex;
              justify-content: flex-start;
              .how-guide-btn {
                display: none;
              }
              .primary-btn {
                border: 1px solid #f68b28;
              }
              .primary-btn:focus{
                border: 2px solid #fff;
                outline: 2px solid #f68b28;
                font-weight: 600;
              }
              .secondary-btn {
              }
            }
          }
          .howToPlayGuideContainer {
          }
        }
      }
    }

    .right-side {
      .game-container {
        padding: 0;
        .game-board {
          min-height: 400px;
          height: 600px;
          width: 600px;
          .top-row {
            display: flex;
            position: relative;
            top: 0px;
            height: 89px;
            .game-block {
              height: 89px;
              width: 46px;
            }

            .corner-block {
              height: 89px;
              width: 86px;
            }

            .corner-block:last-child {
              height: 89px;
              width: 86px;
            }
            
          }
          .middle-row {
            padding-left: 0;
            padding-right: 0;
            position: relative;
            top: 0px;
            height: 420px;   
            .game-block {
              width: 88px;
              height: 47px;
            }

            .center-col {
            }
          }
          .bottom-row {
            display: flex;
            position: relative;
            top: 0px;
            height: 89px;
            .game-block {
              height: 89px;
              width: 46px;
            }

            .corner-block:first-child {
              height: 89px;
              width: 86px;
            }

            .corner-block:last-child {
              height: 89px;
              width: 86px;
            }
          }

          .avatar {
            display: none !important;
            position: absolute;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            // bottom: 10px;
            // right: 10px;
            right: 30px;
            bottom: 25px;
            background-color: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 1, 5);

            .avatarImg {
              height: 15px;
              width: 15px;
              background: url(../public/game-token.svg);
              background-size: cover;
              background-position: top center, bottom center;
              background-repeat: no-repeat;
            }
          }

          .dice-area {
            position: absolute;
            height: 52px;
            width: 104px;
            top: 30%;
            left: 30%;
            pointer-events: none;
          }

          .question-form {
            display: flex !important;
            max-width: 200px !important;
          }
        }
      }
    }

    .email-input-box {
      padding: 12px;
      margin: 10px 0;
      border-radius: 8px;
      border: 2px solid #fb8b23;
      outline: none;
      font-size: 1rem;
      color: #1e1e1e;
    }
  }
}

.token{
  background: url(../public/avatar.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px,40px;
}

h3,h4 {
  font-family: "Montserrat", sans-serif !important;
}