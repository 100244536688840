@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");



.landing-page {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  flex-direction: column;
  color: #ffffff;
  background: url(../public/homepage-background.webp);
  // background: url(../public/homepage-background.webp),
  //   url(../public/Group-13980.webp);
  background-size: cover;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
  padding-left: 64px;
  padding-right: 64px;
  .header {
    padding: 24px 12px 24px 12px;
    z-index: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    // position: fixed;
    // width: 100%;
    // background: #fff;
    // left: 0;
    // top: 0;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    // z-index: 0;
  }
  .videoWrapper {
    position: absolute;
    top: 98px;
    left: 0;
    width: 100vw;
    height: calc(100% - 98px);
    object-fit: cover;
    z-index: -1;
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      object-fit: cover;
      z-index: -1;
      height: 100%;
    }
  }
  .content-wrapper {
    // height: calc(100% - 136px);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: center;
    align-items: center;
    .left-section {
      width: 50%;
      height: 100%;
      background: transparent !important;
      .slide {
        display: flex !important;
        flex-direction: row !important;
        gap: 30px !important;
        height: 474px;
        img {
          height: 474px;
        }
      }
    }
    .right-section {
      background: rgb(6 6 6 / 66%);
      backdrop-filter: blur(15px);
      padding-top: 5%;
      padding-left: 10%;
      padding-right: 10%;
      border-radius: 15px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      height: 90%;
      width: 60%;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 42px;
        font-weight: 300;
        color: #fff;
        margin-bottom: 42px;
        text-align: center;
        strong {
          font-weight: 900;
          border-bottom: 6px solid;
          color: #fb8b23;
        }
      }
      .subtitle {
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 0px;
      }
      .styled-checkbox {
        width: 14px;
        height: 14px;
        border: 2px solid #fb8b23;
        background-color: rgba(255, 255, 255, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 4px;
        margin-top: 2px;
        cursor: pointer;
      }
      
      .styled-checkbox:checked {
        background-color: #fb8b23;
      }
      
      .styled-checkbox:hover {
        box-shadow: 0 0 5px 2px rgba(251, 139, 35, 0.6); /* Glowing effect */
      }
      
      .checkbox-wrapper {
        font-size: 10px;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        margin-bottom: 15px;
      }
      
      .email-input-box {
        width: 100%;
        padding: 12px;
        margin: 10px 0;
        border-radius: 8px;
        border: 2px solid #fb8b23;
        outline: none;
        font-size: 1rem;
        color: #fff;
        background-color: rgb(255 255 255 / 10%);
        transition: box-shadow 0.3s ease;
        &:focus {
          box-shadow: 0 0 10px 3px rgba(251, 139, 35, 0.8); /* Glowing effect */
        }
      }
      .otp-input-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        margin-top: 8px;
        .otp-box {
          width: 80px;
          height: 50px;
          font-size: 1.5rem;
          text-align: center;
          border: 2px solid #fb8b23;
          outline: none;
          font-size: 20px;
          color: #fff;
          background-color: rgb(255 255 255 / 10%);
          outline: none;
          margin-right: 5px;
          border-radius: 8px;
          transition: box-shadow 0.3s ease;

          &:last-child {
            margin-right: 0;
          }
          &:focus {
            box-shadow: 0 0 10px 3px rgba(251, 139, 35, 0.8); /* Glowing effect */
          }
        }
      }

      .resend-otp{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        margin-bottom: 12px;

        a{
          color: #fb8b23 !important;
          font-weight: 600;
        }
      }

      .proceed-button,
      .play-button {
        width: 100%;
        padding: 12px;
        border: none;
        border-radius: 8px;
        font-size: 22px;
        height: 60px;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        background-color: #fb8b23;
        margin-top: 10px;
        transition: background-color 0.3s ease;
        border-radius: 30px;
        &:hover {
          background-color: #26619c;
          -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
          transform: translate3d(0, -1px, 0);
          transition: All 300ms ease;
        }
      }

      .play-button {
        margin-top: 10px;
        background-color: #fb8b23;

        &:hover {
          background-color: #26619c;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .landing-page {
    padding-left: 16px;
    padding-right: 16px;
    background: url(../public/homepage-background.webp);

    .header{
      z-index: 0;
      justify-content: center;
    }
    .videoWrapper {
      position: absolute;
      top: 98px;
      left: 0;
      width: 100vw;
      height: calc(100% - 98px);
      object-fit: cover;
      z-index: -1;
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        object-fit: cover;
        z-index: -1;
        height: 100%;
      }
    }
    .content-wrapper {
      flex-direction: column;
      gap: 15px;
      align-items: center;
      .right-section {
        width: 90%;
        padding: 5%;
        height: 60%;
        .title {
          font-size: 24px;
          margin-bottom: 24px;
        }
        .subtitle {
          font-size: 1rem;
          margin-bottom: 8px;
        }
        .email-input-box,
        .otp-box {
          width: 100%;
          font-size: 0.9rem;
          height: 45px;
        }
        .otp-input-wrapper {
          .otp-box {
            width: 45px;
            height: 45px;
          }
        }
        .proceed-button,
        .play-button {
          font-size: 18px;
          height: 50px;
          margin-top: 8px;
        }
      }
    }
  }


}


@media only screen and (min-width: 480px) and (max-width: 768px) {
  .landing-page {
    padding-left: 16px;
    padding-right: 16px;
    .header{
      z-index: 0;
      justify-content: center;
    }
    .content-wrapper {
      flex-direction: column;
      gap: 15px;
      align-items: center;
      .right-section {
        width: 80%;
        padding: 5%;
        height: 60%;
        .title {
          font-size: 32px;
          margin-bottom: 24px;
        }
        .subtitle {
          font-size: 1rem;
          margin-bottom: 8px;
        }
        .email-input-box,
        .otp-box {
          width: 100%;
          font-size: 0.9rem;
          height: 45px;
        }
        .otp-input-wrapper {
          .otp-box {
            width: 45px;
            height: 45px;
          }
        }
        .proceed-button,
        .play-button {
          font-size: 18px;
          height: 50px;
          margin-top: 8px;
        }
      }
    }
  }


}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .landing-page {
    padding-left: 16px;
    padding-right: 16px;
    .header{
      z-index: 0;
    }
    .content-wrapper {
      flex-direction: column;
      gap: 15px;
      align-items: center;
      .right-section {
        width: 80%;
        padding: 5%;
        height: 80%;
        .title {
          font-size: 32px;
          margin-bottom: 24px;
        }
        .subtitle {
          font-size: 1rem;
          margin-bottom: 8px;
        }
        .email-input-box,
        .otp-box {
          width: 100%;
          font-size: 0.9rem;
          height: 45px;
        }
        .otp-input-wrapper {
          .otp-box {
            width: 60px;
          }
        }
        .proceed-button,
        .play-button {
          font-size: 18px;
          height: 50px;
          margin-top: 8px;
        }
      }
    }
  }


}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .landing-page {
    padding-left: 16px;
    padding-right: 16px;
    .header{
      z-index: 0;
    }
    .content-wrapper {
      flex-direction: column;
      gap: 15px;
      align-items: center;
      .right-section {
        width: 70%;
        padding: 5%;
        height: 70%;
        .title {
          font-size: 32px;
          margin-bottom: 24px;
        }
        .subtitle {
          font-size: 1rem;
          margin-bottom: 8px;
        }
        .email-input-box,
        .otp-box {
          width: 100%;
          font-size: 0.9rem;
          height: 45px;
        }
        .otp-input-wrapper {
          .otp-box {
            width: 60px;
          }
        }
        .proceed-button,
        .play-button {
          font-size: 18px;
          height: 50px;
          margin-top: 8px;
        }
      }
    }
  }


}